
@font-face {
  font-family: 'AstyCFStdBook';
  src: url('../fonts/Asty/AstyCFStd-Book.otf');
}

@font-face {
  font-family: 'AstyCFStdLight';
  src: url('../fonts/Asty/AstyCFStd-Light.otf');
}

@font-face {
  font-family: 'AstyCFStdMed';
  src: url('../fonts/Asty/AstyCFStd-Medium.otf');
}

@font-face {
  font-family: 'AstyCFStdThin';
  src: url('../fonts/Asty/AstyCFStd-Thin.otf');
}

@font-face {
  font-family: 'AstyCFStdBold';
  src: url('../fonts/Asty/AstyCFStd-Bold.otf');
}

@font-face {
  font-family: 'AstyCFStdExtraBold';
  src: url('../fonts/Asty/AstyCFStd-ExtraBold.otf');
}

@font-face {
  font-family: 'AstyCFStdUltra';
  src: url('../fonts/Asty/AstyCFStd-Ultra.otf');
}
