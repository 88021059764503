$fa-font: 'Font Awesome 5 Free';
$fa-font-path: "../webfonts";

$primaryColor: #E90A8A;
$secondaryColor: #000;
$grey-border: gray;
$grey-border-thick: gray;
$mineShaft: #333333;
$scorpion: #5E5E5E;
$mercury: #E6E6E6;
$pink1: #E90A8A;
$atol: #09787C;
$dustyGray: #9A9A9A;
$scorpion2: #5A5A5A;
$lightGray1: #ECECEC;
$lightGray2: #E1E1E1;
$lightGray3: #E3E3E3;
$lightGray4: #E5E5E5;
$lightGray5: #DCDCDC;
$gray1: #ACACAC;
$white: #fff;

$asty: 'AstyCFStdBook';
$astyL: 'AstyCFStdLight';
$astyB: 'AstyCFStdBold';
$astyEB: 'AstyCFStdExtraBold';
$astyM: 'AstyCFStdMed';
$astyT: 'AstyCFStdThin';
$astyU: 'AstyCFStdUltra';

$z-index-dropdown: 100;

%content {
  content: '';
  position: absolute;
}

%bold {
  font: {
    weight: 600;
  }
}

%font-awesome {
  font: {
    family: "Font Awesome 5 Free";
    weight: 600;
    size: 15px;
  }
}
