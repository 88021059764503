#checkout-cart {
  #content {
    margin: 50px 0 100px 0;

    form {
      margin-bottom: 50px;
    }
  }

  a:not(.btn-primary) {
    color: black;

    &:hover {
      color: #444;
    }
  }

  .input-group {
    .form-control[name^="quantity"] {
      border: 1px solid $primaryColor;
      border-radius: 6px;
    }
  }

  .btn-update {
    margin-left: 5px;
    margin-right: 2px;
    height: 38px;
  }

  .btn-primary {
    min-height: 38px;
  }

  input[type="text"],
  select {
    border-radius: 6px;
    border: 1px solid $primaryColor;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .cart {
    margin-bottom: 2.5rem;
  }

  .cart__product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 0 0.5rem;
    border-top: 1px solid #dde2e6;
    border-bottom: 1px solid rgba(221, 226, 230, 0.5);
    padding: 10px 0px;

    &__image {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &__details {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      &__info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        &__main {
          margin-bottom: 0.75rem;
        }

        &__price {
          font-size: 0.85rem;
        }

        &__name {
          color: #000;
          font-size: 1rem;
        }

        &__span {
          font-size: 0.65rem;
        }
      }

      &__actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        &__input {
          width: 80px;
        }

        button {
          margin: 0px 0.5rem;
        }
      }
    }
  }
}

#checkout-checkout {
  #content {
    margin: 50px 0 100px 0;

    form {
      margin-bottom: 50px;
    }
  }
}

.cart-aside {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1031;
  top: 0;
  right: 0;
  background-color: $lightGray2;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: .25s linear;

  &.show {
    width: 24.5%;
  }
}

#cart {
  ul {
    padding: 0 30px;
    list-style: none;
    color: black;

    li {
      &.cart-first-child {
        margin-bottom: 1em;

        & > div {
          @include paddingX(0);

          &:last-child {
            text-align: right;
          }
        }
      }

      a {
        color: black;
      }

      &.empty {
        padding: 30px 0;
        text-align: center;
      }

      .totals {
        .total-row {
          margin: 10px 0;
          padding: 15px 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          border-radius: 32px;

          td {
            padding: 0;

            &:first-child {
              font-size: 15px;
              font-family: $astyM;
            }
            &:last-child {
              font-size: 18px;
              font-family: $astyB;
            }
            border: 0;
          }
        }
      }

      .btn-checkout {
        background-color: black;
        display: block;
        border-radius: 32px;
        padding: 15px 0;

        a {
          font-size: 18px;
          color: white;
          text-decoration: none;
        }

        &:hover {
          background-color: $pink1;

          a {
            color: black;
          }
        }
      }

      table {
        td {
          img[src*="trash-white.png"] {
            display: none;
          }
        }
      }
    }
  }
}

.cart {  
  .price {
    font-size: 18pt;
    font-family: $astyB;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 7.94%;
    border-bottom: 2px solid white;
    margin-bottom: 42px;
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      color: black;
      width: 56px;

      img[src*="cart"] {
        height: 21px;
      }
    }

    & > span {
      color: black;
      font-family: $astyB;
      font-size: 21px;
    }
  }
}
