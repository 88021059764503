@media screen and (min-width: 992px) {
  .modal-dialog {
    max-width: 800px;
  }
  .header {
    &--desktop {
      display: flex;
      transition: all linear .3s;
      &.fixed {
        position: fixed;
        z-index: 2000;
        width: 100%;
        height: auto;
        top: 0;
      }
    }
    &--mobile {
      display: none;
    }
    #menuTrigger {
      display: none;
    }
    .menu-offcanvas--mobile {
      display: none;
    }
    .menu-offcanvas--mobile {
      &:not(.mm-menu--opened) {
        width: 0%;
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
  #cart-aside {
    .cart {
      &__header {
        &.mobile {
          display: none;
        }
        &.desktop {
          display: flex;
        }
      }
    }
  }

  .login-aside {
    &__header {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: flex;
      }
    }
  }

  .footer {
    &--desktop {
      display: block;
    }
    &--mobile {
      display: none;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1700px) {
  .header {
    &__col-first {
      &__menu {
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        row-gap: 3px;

        &>span {
          font-size: 1.8vw;
        }

        #menu-trigger-desktop {
          flex: 0 0 21%;
          max-width: 21%;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &__col-second {
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;

      #search {
        flex: 0 0 60%;
        max-width: 60%;
      }
    }
  }

  .home {
    &__testimonials {
      &__left {
        h2 {
          max-width: 50%;
        }
      }
    }
    &__stock-house {
      &__left {
        .offer {
          &__title {
            font-size: 2vw;
          }
        }
      }
    }
    &__text-block-1 {
      &>.col-12 {
        h1 {
          font-size: 3.5vw;
        }
      }
    }
  }

  .module {
    &__top-row {
      &__left {
        h3 {
          font-size: 5vw;
        }
      }
    }
    .module {
      &__body {
        .product-row {
          .product-layout {
            flex: 0 0 24%;
            max-width: 24%;

            // .product-thumb {
            //   min-height: 420px;
            // }
          }
        }
      }
    }
    #featured-tab {
      .nav-item {
        .nav-link {
          font-size: 1.7vw;
        }
      }
    }
  }

  .product_image_col {
    .owl-item {
      img {
        height: auto;
        max-height: 500px;
      }
    }
  }

  .product_details_col {
    #product {
      max-width: 100%;
      padding: 0;
    }
    .multi-container-1 {
      .qty {
        font-size: 1.6vw;
      }
      .new-price {
        font-size: 2.1vw;
      }
      .special {
        font-size: 2.4vw;
      }
    }
  }

  .login-aside, #cart-aside {
    &.show {
      width: 42%;
    }
  }
}
@media only screen and (max-width:1199px) {
  .login-aside, #cart-aside {
    &.show {
      width: 50%;
    }
  }
  .module {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width:991px) {
  .module {
    &__body {
      .product-row {
        column-gap: 10%;

        .product-layout {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
  }
}
@media only screen and (max-width:991px) {
  /* fix mobile menu break */
  .hc-nav-original.hc-nav-1, 
  #menu-offcanvas--mobile:not(.mm-menu) {
      display: none;
  }
  .box-heading {
    font-size: 18px;
    font-weight: 400;
    margin: 10px auto;
  }
  .mm-panel--opened {
    z-index: 1;
  }
  .header {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: flex;
      justify-content: space-between;
      margin-left: -15px;
      margin-right: -15px;
      background-color: #333333;

      &.fixed {
        position: fixed;
        margin: 0;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: $z-index-dropdown + 1;
      }

      &__col-1 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .search-mobile{
        flex:0 0 10%;
        max-width:10%;
        display:flex;
        align-items:center;
        justify-content: center;
        button{
          background:transparent;
          border:none;
          img{
            width:28px;
          }
        }
      }
      &__col-2,
      &__col-3 {
        /*flex: 0 0 20%;*/
        /*max-width: 20%;*/
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
      }
      &__col-4 {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .logo {
        padding: 15px 4%;
        img {
          display: block;
          margin: auto;
          max-height: 51px;
        }
      }
    }
    #menuTrigger {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      i {
        color: white;
        font-size: 30px;
      }
    }
    .menu-offcanvas--mobile {
      display: block;
    }
  }

  .mm-navbar {
    &__title {
      &>span {
        color: black;
        overflow: initial;
        /*font-size: 3vw;*/
        font-family: $astyB;
      }
    }
  }

  .mm-menu {
    a.mm-listitem__text {
      &:active {
        background-color: rgba($color: $lightGray1, $alpha: .5);
        color: $primaryColor;
      }
    }
  }

  .breadcrumb {
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .login-aside {
    &.show {
      width: 85%;
    }

    &__form {
      margin-top: 14vh;
    }

    &__footer {
      margin-top: 14vh;
    }
  }

  .cart-aside {
    &.show {
      width: 85%;
    }
  }

  .cart-trigger {
    position: relative;
    .product_counter {
      position: absolute;
      left: calc(50% + 15px);
      top: calc(50% - 15px);
      color: #fff;
      background-color: #e90a8a;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
    }
  }

  .home {
    &__stock-house {
      &__left,
      &__right {
        flex: 0 0 100%;
        max-width: 100%;
        border-width: 2px;
      }
      &__right {
        padding: 25px 15px;
      }
    }
    &__newsletter {
      margin-top: 1em;
    }
    &__text-block-1 {
      padding: 60px;
      >.col-12 h1 {
        font-size: calc(max(22px,1.6vw));
      }
    }
  }

  .options_row {
    padding: 0;
    margin-top: 15px;

    .inner {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      column-gap: 0;
      padding: 0;
      border: 0;

      .limit-box,
      .sort-box,
      .filter-box {
        flex: 0 0 50%;
        max-width: 50%;
        border-radius: 0;
        /*height: 100%;*/
        padding: 15px 5px;

        label {
          display: none;
        }
      }
      .filter-box {
        display: none;
        background-color: black;
      }
      .sort-box,
      .limit-box {
        .input-group {
          position: relative;

          select {
            margin-left: 0;
            background-color: initial;
            border-radius: 0;
            text-align: center;
            border: 0;
            font-size: 15px;
            font-family: $astyM;
          }
          &::after {
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            font-family: $fa-font;
            content: '\f107';
            font-size: 1.2em;
          }
        }
      }
      .sort-box {
        background-color: #CFCFCF;
      }
      .limit-box {
        background-color: #E9E9E9;
      }
    }
  }

  .module {
    &__top-row {
      &__left {
        text-align: center;
        h3 {
          font-size: 3vw;
        }
      }
    }

    &__body {
      .product-layout {
        .product-thumb {
          padding-bottom: 8px;

          .cart {
            display: block;
            position: relative;

            .btn {
              background-color: black;
              color: white;

              &:hover,
              &:active {
                background-color: $primaryColor;
                color: white;
              }
            }
          }
        }
      }
    }

    #related-swiper {
      padding: 0;
    }

    #featuredTabContent {
      padding: 0 10%;
    }

    #featured-tab {
      justify-content: center;
    }
  }

  .footer {
    &__top {
      &__center,
      &__left,
      &__right {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    &__center {
      row-gap: 25px;
    }
    &__bottom {
      row-gap: 25px;
    }
  }

  #product-product {
    .inner {
      .productmain {
        .table-responsive {
          display: block;
        }
      }
    }
  }

  .product_tabs_col {
    position: static;
    margin-bottom: 0;
  }

  .product_details_col {
    padding-top: 20px;
    padding-bottom: 20px;
    row-gap: 20px;

    .multi-container-1 {
      display: none !important;
    }
    .product-buttons-wrap {
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;

      .wishlist {
        background-color: red;
        width: 66px;
        height: 66px;
        flex: 0 0 66px;
        max-width: 66px;
      }

      #button-cart {
        display: none;
      }
    }
  }

  .product_image_col {
    .owl-item {
      img {
        height: auto;
      }
    }
  }

  #d_ajax_search_results {
    /*left: 10%;*/
    width: 80% !important;
    overflow: auto;
    max-height: 75vh;
    top: 0;
  }

  #addcartfixed--mobile {
    #product--mobile {
      height: 150px;
    }
    .prices {
      padding: 15px 5px;
      background-color: black;

      ul {
        display: flex;
        flex-flow: column wrap;
        row-gap: 5px;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        li {
          font-size: 20px;
          color: white;

          &:not(:last-child) {
            margin-right: 0;
          }

          &.special + .new-price {
            text-decoration: line-through;
            color: red;
            font-size: 15px;
          }
        }
      }
    }
    .qty {
      background-color: #CBCBCB;

      .input-group {
        justify-content: center;

        .btn {
          color: black;
        }
        .form-control {
          background-color: #CBCBCB;
          text-align: center;
          padding-left: 0;
          padding-right: 0;
          max-width: 30%;
        }
      }
    }
    .cart {
      background-color: $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  #checkout-checkout {
    #content {
      margin: 25px 0 100px 0
    }
    h2 {
      margin-top: 1em;
    }
  }

  #cart-aside {

    .cart {
      &__header {
        &.mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 7.94%;
          border-bottom: 2px solid white;
          margin-bottom: 42px;

          .title {
            font-size: 21px;
          }
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            color: black;
            width: 56px;

            img[src*="cart"] {
              height: 21px;
            }
          }
        }
        &.desktop {
          display: none;
        }
      }
    }
  }

  .panel.panel-default {
    padding: 25px 0;
  }

  #cart {
    ul {
      li {

        a.d-block:first-child {
          font-size: 12pt;
          font-family: $asty;
          margin-bottom: 14px;
        }
        .totals {
          .total-row {
            color: black;
          }
        }
        table {
          td {
            &.product-cell-left {
              flex: 0 0 20%;
              max-width: 20%;
            }
          }
          &.nested {

            td {
              border: 0 !important;
            }
          }
        }
        .table-bordered {
          border: 0 !important;
        }
      }
    }
  }

  .login-aside {
    &__header {
      &.mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 7.94%;
        border-bottom: 2px solid white;
        margin-bottom: 0;

        .title {
          font-size: 21px;
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          color: black;
          width: 56px;

          .fa-angle-left {
            font-size: 18px;
          }
          img {
            width: auto;
            height: 18px;
          }
        }
      }
      &.desktop {
        display: none;
      }
    }
    &__form {
      margin-top: 7vh;
    }
  }

  #main {
    div[id^="common-"] {
      margin-bottom: 4em;
    }
  }

  .footer {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;

      padding: 43px 15px 0 15px;
      background-color: $mineShaft;

      & > div {
        padding-left: 18px;
        padding-right: 18px;
      }
      hr {
        border-color: #707070;
        border-top-width: 1.5pt;
      }
      &__row1 {
        padding: 0 18px;
        h3 {
          margin-bottom: 20px;
          span {
            font-size: 15pt;
            font-family: $astyM;
          }

          color: white;
          padding: 16px 5.3%;
          border-radius: 26pt;
          border: 1px solid #707070;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        ul {
          display: none;

          li {
            text-align: center;
            color: #fff;

            a {
              color: #fff;
              display: inline-block;
              font-size: 17px;
              font-family: $astyL;
              line-height: 30px;
            }
          }
        }

        .acs {
          padding: 50px 0 45px 0;
          text-align: center;
          h4 {
            flex-basis: 100%;
            margin-bottom: 22px;
            text-align: center;
            color: white;
            font-size: 18px;
            font-family: $asty;
          }
        }
      }

      &__row2 {
        .inner {
          padding: 31px 0;
          justify-content: center;
          align-items: center;
          column-gap: 10%;
        }
      }

      &__row3 {
        .inner {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
        }
      }

      &__row4 {
        .inner {
          padding: 30px 0;

          ul {
            li {
              font-size: 16px;
              color: white;
              text-align: center;
              font-family: $asty;

              &:not(:last-child) {
                margin-bottom: 15px;
              }

              a {
                color: white;
                text-decoration: underline;
              }
            }
          }
        }
      }

      &__row5 {
        .inner {
          padding: 33px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__row6 {
        .inner {
          padding: 33px 0;
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;
          row-gap: 16px;
          color: white;

          span {
            margin-bottom: 25px;
          }
          .copy {
            text-align: center;
            font-size: 16px;
          }
          .dwhite {
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }
  }
  .login-aside, #cart-aside {
    &.show {
      width: 80%;
    }
  }
}

@media only screen and (max-width:767px) {
  .options_row {
    .inner {
      row-gap: 25px;
    }
  }
  .dont-forget {
    &__bottom {
      row-gap: 25px;
    }
  }
  .home {
    &__newsletter {
      margin-top: 60px;
      &__form {
        .col-left {
          .title {
            max-width: 100%;
          }
        }
        .col-right.mobile {
          margin-right: auto;
          margin-left: auto;
          width: auto;
        }
      }
    }
  }
  #product-product {
    .inner {
      padding: 0 15px;
    }
  }
  .breadcrumb {
    li {
      font-size: 3.5vw;
      padding: 5px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  .product_image_col {
    .owl-thumbs,
    .owl-carousel {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .owl-thumbs {
      order: 2;
      margin: 25px 0;
      display: flex;
      flex-flow: row wrap;
      column-gap: 5%;
      row-gap: 10px;
      justify-content: center;
      align-items: center;

      .owl-thumb-item {
        border: 1px solid black;

        &:not(:last-child) {
          margin-bottom: initial;
        }
      }
    }

    .owl-carousel {
      order: 1;
    }
  }

  .product_details_col {
    .product_title {
      max-width: 100%;
      margin: 0;
    }
    #product {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .multi-container-1 {
      .qty {
        font-size: 15px;
      }
      .special,
      .new-price {
        font-size: 17px;
      }
    }
    .product-buttons-wrap {
      .wishlist {
        width: 40px;
        height: 40px;
        padding: 0;
        max-width: 40px;
        flex: 0 0 40px;
        .fa {
          font-size: 15px;
        }
      }
      #button-cart {
        font-size: 17px;
      }
    }
  }

  #addcartfixed {
    margin-left: 0;
    margin-right: 0;
    max-width: 100vw;
    overflow: hidden;

    & > .col-12 {
      padding: 10px 15px;
      row-gap: 10px;
      justify-content: center !important;
    }
  }

  #search_mobile {
    & > .pull-left {
      i {
        padding: 8px 5px 0 5px;
        font-size: 19px;
      }
    }
    & > .pull-right {
      i {
        padding: 8px 5px 0 5px;
        font-size: 19px;
      }
    }
  }

  h1 {
    font-size: 28px;
  }

  .well {
    h2 {
      margin-top: 1em;
    }
  }

  #main {
    div[id^="account-"],
    div[id^="common-"],
    div[id^="information-"] {

      #content {
        padding: 0 15px;
        line-height: 1.5;

        .col-sm-3 {
          margin-top: 25px;
        }
      }
    }

    #error-not-found {
      #content {
        margin: 50px 0 100px 0;
      }
    }
  }
  .productmain {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .pagination {
    justify-content: start;
    flex-wrap: wrap;
  }

  .dont-forget {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 1em;
  }
  .module {
    padding: 0 15px;
    &__body {
      .product-row {
        padding: 0 4.5%;
      }
    }
  }
  .login-aside, #cart-aside {
    &.show {
      width: 90%;
    }
  }

  .module__body .product-layout .product-thumb .details .product-name {
    height: auto;
    min-height: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .module {
    &__body {
      .product-row {
        .product-layout {
          flex: 0 0 45%;
          max-width: 45%;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) {
  .home-landing-swiper {
    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }

  .home__stock-house {
    &__right {
      &.desktop {
        display: flex;
      }
      &.mobile {
        display: none;
      }
    }
  }

  .home__newsletter {
    &__form {
      .col-right {
        &.mobile {
          display: none;
        }
        &.desktop {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .module {
    &__top-row {
      &__left {
        h3 {
          font-size: 34px;
          margin-bottom: 1em;
        }
      }
    }
    #featured-tab {
      .nav-item {
        .nav-link {
          font-size: 16px;
        }
      }
    }
    &__body {
      .product-row {
        .product-layout {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
  .home {
    &__text-block-1 {
      padding: 15%;

      &>.col-12 {
        h1 {
          font-size: 7vw;
          text-align: center;
        }
      }
    }
    &__stock-house {
      &__left {
        column-gap: 5%;

        .offer {
          &__title {
            font-size: 19px;
          }
          &__subtitle {
            font-size: 15px;
          }
        }
      }
    }
  }

  .home-landing-swiper {
    &.mobile {
      display: block;
      margin: 17px 10px 0 10px;
    }
    &.desktop {
      display: none;
    }
  }

  .home__stock-house {
    &__right {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        position: relative;
        padding: 0;

        .overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 100%;

          h2,
          h3 {
            text-align: center;
            color: white;
            margin-bottom: 0;
          }

          h2 {
            font-family: $astyB;
            font-size: 15vw;
            margin-bottom: 10px;
          }
          h3 {
            font-family: $asty;
            font-size: 10vw;
          }
        }
      }
    }
  }

  .home__newsletter {
    &__form {
      .col-right {
        &.mobile {
          margin-top: 54px;
          display: block;
        }
        &.desktop {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width:440px){
  .live-search ul li .product-name{
    width:57%!important;
  }

  .live-search ul li .product-price{
    width:17%!important;
  }
}

@media only screen and (max-width:480px) {}

@media only screen and (max-width:414px) {}

@media only screen and (max-width:375px) {}


@media screen and (max-width:480px){
  .home__stock-house__left .offer{
    flex:0 0 45%;
    max-width:45%;
  }
}


@media screen and (max-width:756px){
  .module #featured-tab{
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    height: 60px;
    padding-left: 7em;
    margin-bottom: 0;
    list-style: none;
    row-gap:unset;
    column-gap:unset;
    .nav-item{
      flex:0 0 auto!important;
    }
  }
}


@media screen and (max-width:992px){
  .table-responsive{
    .input-group{
   
      .input-group-btn{
        margin-top:10px;
        display:flex;
      }
    }
  }
}
@media screen and (max-width:576px){
  .thumbsWrapper{
    padding:30px 20px 40px 20px;
    overflow:visible;
    position: relative;
    height: unset;
    display:block;

    img{
      width:88px;
    }

    .swiper-slide-thumb-active{
      img{
        border-bottom: 2px solid #e90a8a;
        
      }
    }
    .swiper-button-prev{
      position:absolute;  
      left:-20px;
      top:40%;
      right: unset;
      bottom: 0;
      transform: unset;
    }
   .swiper-button-next {
      position: absolute;
      right: -20px;
      top: 40%;
      left:unset;
      bottom: 0;
      transform: unset;
  }

   .swiper-slide {
    height: 88px!important;
    width: 88px;
    max-height: 88px;
}

  }

  .product_image_col {
    display: block;
}
}

@media screen and (min-width:577px) and (max-width:992px){
  .thumbsWrapper{
    padding:30px 20px 40px 20px;
    overflow:visible;
    position: relative;
    height: unset;
    display:block;

    img{
      width:88px;
    }

    .swiper-slide-thumb-active{
      img{
        border-bottom: 2px solid #e90a8a;
        
      }
    }
    .swiper-button-prev{
      position:absolute;  
      left:-20px;
      top:40%;
      right: unset;
      bottom: 0;
      transform: unset;
    }
   .swiper-button-next {
      position: absolute;
      right: -20px;
      top: 40%;
      left:unset;
      bottom: 0;
      transform: unset;
  }

   .swiper-slide {
    height: 88px!important;
    width: 88px;
    max-height: 88px;
}

  }

  .product_image_col {
    display: block;
}
}


@media screen and (min-width: 992px) and (max-width: 1790px){
.product_details_col, .product_image_col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

 .product_image_col {
  #product-images{
    flex:0 0 auto;
    width:70%;
  }
}

.thumbsWrapper {
  margin-right: 20px;
}

}

@media screen and (min-width:1200px){
	#checkout-cart {
		max-width: 1200px;
		padding:0;
	}
}

@media screen and (min-width:993px) and (max-width:1200px){
#checkout-cart {
	.input-group{
		&.btn-block{
			fisplay:flex;
			flex-wrap:wrap;
			justify-content:center;
		}
		
		.form-control[name^=quantity]{
			flex:0 0 auto;
			width:100%;
			margin-bottom:10px;
		}
	}
	
}


	
	 
}