:root {
  --swiper-theme-color: #{$primaryColor};
  --swiper-navigation-color: #{$primaryColor};
}

body {
  font-family: $asty;
  font-weight: 400;
  color: black;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $astyB;
  color: #444;
}

/* default font size */
.fa {
  font-size: 18px;
  color: #000;
}

/* Override the bootstrap defaults */
h1 {
  font-size: 33px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10.2px;
}

a {
  color: black;

  &:hover {
    color: $primaryColor;
  }
}

// a:hover {
//   text-decoration: none;
//   color: $secondaryColor;
// }

legend {
  font-size: 18px;
  padding: 7px 0px
}

label {
  font-size: 14px;
  font-weight: normal;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

button {
  outline: none !important;
}

.btn-primary {
  color: #fff;
  background-color: $primaryColor;
  border-color: $primaryColor;

  &:hover,
  &:active {
    background-color: black !important;
    color: white !important;
    border-color: initial !important;
  }
}

/*GENERAL */
#logo {
  margin: 0 0 10px 0;
}

#common-home {
  margin-bottom: 6rem;

  .right_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.owl-item {
  .product-layout {
    .product-thumb {
      img {
        display: inline-block;
      }
    }
  }
}

.popover-header:empty {
  display: none;
}

/* MOBILE MENU */
#mp-menu {
  display: none;

  &.mm-menu_opened {
    display: block;
    z-index: 6;
  }
}

.mm-wrapper_opened {
  .mm-menu_fx-menu-slide {
    -webkit-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.mm-wrapper_opening {
  .mm-menu_fx-menu-slide {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.mm-btn {
  &.mm-btn_prev {
    &:before {
      border-color: #000;
      width: 12px;
      height: 12px;
    }

    &.mm-navbar__btn {
      left: 0;
    }
  }
}

.required .col-form-label:before {
  content: '* ';
  color: #F00;
  font-weight: bold;
}

.btn-remove {
  .fa {
    color: #fff;
  }
}

.btn, .list-group {
  border-radius: 0;
}

.btn-default {
  border: 1px solid $pink1;
  color: $secondaryColor;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem rgba(255, 188, 22, 0.25);
}

.alert {
  text-align: center;
  background-color: $white;
  border-color: $pink1;
  color: $secondaryColor;
  border: 2px solid;
  border-radius: 0;

  a {
    color: $pink1;

    &:hover {
      color: $scorpion;
    }
  }
}

.dropdown-menu {
  .dropdown-anchor {
    &::after {
      border: 6px solid rgba(0,0,0,0.15);
    }
  }
  ul li label:hover,
  ul li label:not(.grey):hover,
  ul li>a:hover,
  ul li>a:not(.grey):hover {
    background-color: $primaryColor;
    color: white;
  }
}

#accordion {
  .panel {
    &:last-child {
      margin-bottom: 50px;
    }
  }
}
