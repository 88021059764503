.header--desktop{

}

.header {
  position: relative;

  &__col-first {
    flex: 0 0 22.5%;
    max-width: 22.5%;
    padding: 8px 1.3%;
    background-color: $mineShaft;
    justify-content: space-between;
    align-items: center;
    /*background-image: url('../../dist/image/header.jpg');*/

    /*&:hover {
      background-color: $pink1;
    }*/

    &__menu {
      align-items: center;
      flex-flow: row wrap;
      column-gap: 15px;

      &>span {
        font-family: $astyB;
        font-size: calc(max(16px, 1.1vw));
        color: white;
      }

      #menu-trigger--desktop {
        cursor: pointer;

        &>img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &__col-second {
    flex: 0 0 63.8%;
    max-width: 63.8%;
    background-color: $scorpion;
    padding: 15px 32px 15px 9.1%;
    justify-content: space-between;
    align-items: center;
    //overflow-x: hidden;
    overflow-y: initial;
    position: relative;

    #search {
      height: 38px;
      position: relative;
      flex: 0 0 80%;
      max-width: 80%;

      .form-control {
        background-color: transparent;
        border-width: 0 0 1px 0;
        border-radius: 0;
        color: $white;

        &::placeholder {
          font-family: $astyM;
          color: white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 16px;
          transition: all .25s linear;
        }

        &:focus {
          + .input-group-append {
            opacity: 1;
          }
        }
      }

      .input-group-append {
        opacity: 0;
        transition: opacity .25s linear;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;

        .btn {
          display: block;
          height: 100%;
          padding: 0 12px;

          i {
            color: white;
          }
        }
      }

      &:hover {
        .form-control {
          &::placeholder {
            left: 15px;
            transform: translate(0, -50%);
          }
        }

        .input-group-append {
          opacity: 1;
          cursor: pointer;
          z-index: 3;
        }
      }
    }

    &__call {
      display: flex;
      align-items: center;

      &>span {
        margin-right: 10px;
        font-size: 17px;
        font-family: $astyB;
        color: white;
      }

      &>a {
        display: inline-block;
        height: 100%;
      }
    }

    &__call-popup {
      position: absolute;
      transition: all .25s linear;
      right: -100%;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 11.9%;

      a {
        color: black;
        font-size: calc(max(16px, 1.2vw));
        font-family: $astyM;
      }

      &.show {
        right: 0;
      }
    }
  }

  &__col-third,
  &__col-fourth {
    flex: 0 0 6.85%;
    max-width: 6.85%;
    position:relative;

    &:hover {
      background-color: $pink1;

      .product_counter {
        background-color: black !important;
      }
    }
  }

  &__col-third {
    background-color: $mineShaft;

    &>a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &__col-fourth {
    background-color: black;

    #cartTrigger {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;

      .product_counter {
        position: absolute;
        left: calc(50% + 15px);
        top: calc(50% - 29px);
        color: white;
        background-color: $pink1;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
      }
    }
  }

  .menu-dropdown--desktop {
    display: none;

    &>.container-fluid {
      height: 100%;

      &>.row {
        height: 100%;
      }
    }

    &.show {
      border-top: 1px solid darkgray;
      border-bottom: 1px solid darkgray;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 70vh;
      height: 70vh;
      background-color: $mercury;
      z-index: $z-index-dropdown;
    }

    &__col-lvl1,
    &__col-lvl2,
    &__col-lvl3 {
      height: 100%;
      overflow: auto;
      padding: 75px 0 25px 0;

      ul {
        &:not(.active) {
          display: none;
        }

        li {
          font-family: $astyB;
          font-size: 16px;

          &.active {
            background-color: white
          }

          a {
            color: black;
            display: block;
            width: 100%;
            padding: 10px 0 10px 10%;
          }

          &.has-sublvl {
            position: relative;

            a {
              padding-right: 10%;
            }

            &::after {
              position: absolute;
              content: '\f152';
              font-family: $fa-font;
              top: 50%;
              right: 5%;
              transform: translateY(-50%);
              color: $primaryColor;
              font-size: 1.25em;
            }
          }

          &:not(:first-child) {
            border-top: 1px solid gray;
          }

          &:last-child {
            border-bottom: 1px solid gray;
          }
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    &__col-lvl1,
    &__col-lvl2,
    &__col-lvl3 {
      border-right: 1px solid darkgray;
    }

    &__col-banners {
      height: 100%;
      overflow: hidden;
      padding: 4% 2%;
      display: flex;
      justify-content: center;
      align-content: space-between;
      flex-flow: row wrap;
      row-gap: 20px;

      & > img {
        max-height: 45%;
        width: auto;
      }
    }

  }
}

.all-products{
  color:$primaryColor!important;
}

.mobile-search-container{
  z-index:99999;
  h5{
    color:#000;
    font-size:18px;
  }

  .close{
    color:#000;
    opacity:1;
    font-size:30px;
  }

  .modal-body{
    padding-left:0;
    padding-right:0;
  }
}
