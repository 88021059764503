@mixin keyframe ($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin animation ($delay, $duration, $animation, $iteration, $direction: normal, $fillmode: forwards) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillmode;
  -webkit-animation-direction: $direction;
  -webkit-animation-iteration-coun: $iteration;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;
  -moz-animation-iteration-coun: $iteration;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
  animation-iteration-count: $iteration;
}

@mixin paddingX($ammount) {
  padding-left: $ammount;
  padding-right: $ammount;
}

@mixin paddingY($ammount) {
  padding-top: $ammount;
  padding-bottom: $ammount;
}

@mixin marginX($ammount) {
  margin-left: $ammount;
  margin-right: $ammount;
}

@mixin marginY($ammount) {
  margin-top: $ammount;
  margin-bottom: $ammount;
}
