.footer {
  background-color: $mineShaft;

  &__top {
    border-bottom: 1px solid $scorpion;

    &__left,
    &__center {
      border-right: 1px solid $scorpion;
    }

    &__left,
    &__center,
    &__right {
      padding-top: 71px;
      padding-bottom: 68px;

      h3 {
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-size: calc(max(16px, 1.2vw));
        line-height: calc(max(19px, 1.4vw));
        margin-bottom: 33px;
        font-family: $astyB;
      }

      ul {
        li {
          text-align: center;
          color: white;
          a {
            color: white;
            display: inline-block;
            font-size: 16px;
            font-family: $astyL;
            line-height: 30px;

            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }

  &__center {
    padding: 45px 0;
    border-bottom: 1px solid $scorpion;
    position: relative;

    &__left {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      column-gap: 7%;
    }

    &__center {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      h4 {
        flex-basis: 100%;
        text-align: center;
        color: white;
        font-size: 18px;
        font-family: $asty;
      }
    }

    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 30px;
      row-gap: 30px;

      #scroll-to-top {
        position: fixed;
        right: 38px;
        bottom: 0;
        z-index: 1;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  &__bottom {
    color: white;
    padding: 27px 0;

    &__left {
      font-size: 15px;
      font-family: $asty;
    }

    &__center {

    }

    &__right {

    }
  }

}
