.module {
  padding: 0 3.38%;

  &__top-row {
    color: black;
    align-items: center;
    margin-bottom: 100px;

    &__left {
      h3 {
        font-family: $astyM;
        font-size: calc(max(17px, 2.8vw));
        line-height: calc(max(20px, 3.2vw));
        color: black;
        position: relative;
        display: inline-block;

        .counter {
          display: none;
          position: absolute;
          right: -25px;
          top: 0;
          transform: translate(100%, 0);
          text-decoration: underline;
          font-size: 22px;
          font-family: $astyM;
        }
      }
    }

    &__right {
      height: 100%;
    }
  }

  &__body {
    .product-row {
      padding: 0 1.5%;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 8%;
      row-gap: 100px;

      .product-layout {
        flex: 0 0 19%;
        max-width: 19%;
      }
    }

    .product-layout {
      user-select: none;

      .product-thumb {
        min-height: 365px;
        padding-bottom: 5px;

        &:hover {
          .cart {
            display: block;
          }
        }
        .image {
          position: relative;
          margin-bottom: 6px;

          & > a {
            display: block;
            width: 100%;
            text-align: center;
          }
          .wishlist {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
          }
          .instock,
          .lowstock,
          .nostock {
            position: absolute;
            top: 0;
            left: 0;
          }
          .special-percent {
            position: absolute;
            left: 0;
            top: 52px;
            background-color: $pink1;
            font-size: 12.5px;
            font-family: $astyB;
            width: 43px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
          }
        }
        .details {
          .product-name {
            min-height: 42px;
            height: 42px;
            font-size: 17px;
            font-family: $astyEB;
            text-align: center;
            margin-bottom: 12px;
            overflow: hidden;

            & > a {
              display: block;
              width: 100%;
              color: black;

              &:hover {
                color: black;
              }
            }
          }
          .product-model {
            text-align: center;
            font-family: $astyM;
            font-size: 15px;

            .prefix {
              color: $dustyGray;
            }
            .postfix {
              color: $scorpion2;
            }
          }
          .product-price {
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 58px;
            padding: 15px 40px;

            .price-current {
              font-size: 20px;
              font-family: $astyB;
              color: black;
            }
            .price-old {
              color: black;
              font-size: 20px;
              text-decoration: line-through;
              text-decoration-color: red;
            }
          }
        }
        .cart {
         /*width: 100%;*/
         /* position: absolute;
          bottom: 0;
          left: 0;*/

          .btn {
            padding: 10px 5px;
            width: 100%;
            background-color: white;
            color: black;
            font-size: 18px;
            border: 1px solid lightgray;
            border-radius: 25px;
            font-family: $astyB;

            &:hover {
              background-color: black;
              color: white;
            }
          }
        }
      }
    }
  }

  #featured-tab {
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    column-gap: 4%;
    row-gap: 15px;
    border-bottom: none;

    .nav-item {
      position: relative;

      .nav-link {
        color: black;
        font-weight: 600;
        font-size: 17px;
        font-family: $astyM;

        &.active {
          color: $pink1;
          border: 2px solid $pink1;
          border-radius: 23px;
        }

        &:hover:not(.active) {
          border-color: transparent;
        }
      }
    }
  }

  #featuredTabContent {
    .swiper {
      .swiper-button-prev,
      .swiper-button-next {

      }
    }
  }

  &--related-products,
  &--recently-products {
    padding-left: 7.1%;

    h3 {
      font-family: $astyB;
      font-size: 30px;
      color: black;
      margin-bottom: 42px;
    }
  }
}
